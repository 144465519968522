<template>
  <v-app-bar color="transparent" class="elevation-0" height="68" app>
    <v-container>
      <div class="inner">
        <!-- 좌 -->
        <v-btn
          color="white"
          text
          plain
          :ripple="false"
          class="pa-0"
          @click="moveToHome()"
        >
          <span class="gray1--text">C.R Consulting</span>
        </v-btn>
        <!-- <v-img
          src="@/assets/img/layout/logo_wh.png"
          max-width="80"
          class="logo"
          @click="moveToHome()"
        /> -->

        <!-- 우 -->
        <div>
          <!-- 라우팅 -->
          <div v-if="$vuetify.breakpoint.smAndUp">
            <v-btn
              v-for="(i, index) in headerList"
              :key="index"
              color="#d2d2d2"
              text
              plain
              :ripple="false"
              class="header-btn"
              @click="moveToSection(i)"
            >
              {{ i.title }}
            </v-btn>
          </div>

          <!-- 네비게이션 -->
          <v-btn
            v-if="!$vuetify.breakpoint.smAndUp"
            color="gray1"
            icon
            @click="openNav()"
          >
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </div>
      </div>
    </v-container>
  </v-app-bar>
</template>

<script>
export default {
  data: () => ({
    headerList: [
      { title: '법인 재무 컨설팅', to: '#financial-consulting' },
      { title: '세무서비스 점검', to: '#check-tax-service' },
      { title: 'Cash Design', to: '#cash-design' },
      { title: 'Contact Us', to: '#contact-us' }
    ]
  }),
  methods: {
    moveToHome() {
      this.$vuetify.goTo(0)
      this.$router.push('/').catch(() => {})
    },
    moveToSection(val) {
      let element = null

      if (val.to === '#financial-consulting') {
        element = document.getElementById('financial-consulting')
        element.scrollIntoView({ behavior: 'smooth' })
      }

      if (val.to === '#check-tax-service') {
        element = document.getElementById('check-tax-service')
        element.scrollIntoView({ behavior: 'smooth' })
      }

      if (val.to === '#cash-design') {
        element = document.getElementById('cash-design')
        element.scrollIntoView({ behavior: 'smooth' })
      }

      if (val.to === '#contact-us') {
        element = document.getElementById('contact-us')
        element.scrollIntoView({ behavior: 'smooth' })
      }
    },
    openNav() {
      this.$store.commit('toggleNav', true)
    }
  }
}
</script>

<style lang="scss">
header {
  .v-toolbar__content {
    .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: $max-width;
      margin: 0 auto;

      .logo {
        cursor: pointer;
      }

      .header-btn {
        font-weight: normal;
      }
    }
  }
}
</style>
